import { getIdTags } from "@/api/auth";
import axios from "axios";
import { defineStore } from "pinia";

export const useCommonStore = defineStore({
  id: "common",
  state: () => ({
    periodType: "beforeEvent", // beforeEvent, onEvent, eventEnd
    round: 1,
    crmClient: "",
    crmLoginUrl: "",
    crmHomeUrl: "",
    crmResetPasswordUrl: "",
    isRedirect: false,
    status1: "ended",
    status2: "ended",
    agentLocation: "cn",
    totalAmountClaimed: 0,
    modal: {
      // MainModal
      isOpen: false,
      name: "",
      packetOpen: false,
      receiveJudgment: false,
    },
    rewardAmount: "",
    rewardType: "",
    displayFloatingButton: false,
    displayMoney: false,
    currentEvent: "iboffer2025",
    loading: false,
    error: null,
    previousEventName: "IB6Q4",
  }),
  actions: {
    setPeriodType(periodType) {
      this.periodType = periodType;
    },
    setStatus(s1, s2) {
      this.status1 = s1;
      this.status2 = s2;
    },
    setRound(round) {
      this.round = round;
    },
    setCrmClient(url) {
      this.crmClient = url;
    },
    setCrmLoginUrl(url: string) {
      this.crmLoginUrl = url;
    },
    setCrmHomeUrl(url: string) {
      this.crmHomeUrl = url;
    },
    setCrmResetPasswordUrl(url: string) {
      this.crmResetPasswordUrl = url;
    },
    setIsRedirect(t) {
      this.isRedirect = t;
    },
    openModal(name) {
      if (name == "Packet") {
        if (this.modal.receiveJudgment == true) {
          this.modal.isOpen = true;
          this.modal.name = name;
        }
        return;
      } else {
        this.modal.isOpen = true;
        this.modal.name = name;
      }
    },
    closeModal() {
      this.modal.isOpen = false;
      this.modal.packetOpen = false;
      setTimeout(() => {
        this.modal.name = "";
      }, 300);
    },
    setPacketOpen() {
      this.modal.packetOpen = true;
    },
    setReceiveJudgment() {
      this.modal.receiveJudgment = true;
    },
    setRewardAmount(data) {
      this.rewardAmount = data;
    },
    setRewardType(data) {
      this.rewardType = data;
    },
    setDisplayFloatingButton() {
      this.displayFloatingButton = true;
    },
    setHideFloatingButton() {
      this.displayFloatingButton = false;
    },
    setDisplayMoneyTrue() {
      this.displayMoney = true;
    },
    setDisplayMoneyFalse() {
      this.displayMoney = false;
    },
    setAgentLocation(data: string) {
      this.agentLocation = data.toLowerCase();
    },
    async fetchCRMIdTags() {
      this.loading = true;
      this.error = null;
      try {
        const { hostname, pathname } = window.location;
        const { data } = await getIdTags({ hostname, path: pathname });

        if (data.code == 0) {
          const queryString = new URLSearchParams(data.data).toString();
          this.setCrmLoginUrl(`${this.crmLoginUrl}?${queryString}`);
        }
        console.log("crmLoginUrl", this.crmLoginUrl);
        console.log("unable get token data from backend");
        window.location.href = this.crmLoginUrl;
      } catch (err) {
        this.error = err.message || "Failed to fetch data";
      } finally {
        this.loading = false;
      }
    },
    setPreviousEventName(eventName: string) {
      this.previousEventName = eventName;
    },
  },
});
