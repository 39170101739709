export const getUserDevice = (): string => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Check for iOS
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "ios";
  }

  // Check for Android
  if (/android/i.test(userAgent)) {
    return "android";
  }

  // Check for Windows Phone
  if (/windows phone/i.test(userAgent)) {
    return "android";
  }

  // Check for tablets
  //   if (/iPad|Tablet/.test(userAgent)) {
  //     return "Tablet";
  //   }

  // Check for desktops
  if (/Macintosh|Windows|Linux/.test(userAgent)) {
    return "web";
  }

  // Fallback to unknown
  return "h5";
};
