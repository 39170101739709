<template>
  <RouterView />
  <EventEnd v-if="commonStore.modal.name === 'EventEnd'" />
  <InvalidCountry v-if="commonStore.modal.name === 'InvalidCountry'" />
</template>

<script lang="ts">
import { RouterView } from 'vue-router';
import { useI18n } from "vue-i18n";
import { useAgentStore } from '@/stores/user'
import { useCommonStore } from '@/stores/common'
import { useIB4Store } from '@/stores/ib4-0'
import { getCookie, getCollectionId, getUtmPath } from '@/service/cookie'
import { trackId } from '@/api/track'
import EventEnd from "@/components/Modals/EventEnd.vue";
import InvalidCountry from "@/components/Modals/InvalidCountry.vue";
import { getLocation } from './api/location';
import * as Sentry from "@sentry/vue";

export default {
  components: {
    RouterView,
    EventEnd,
    InvalidCountry,
  },
  setup() {
    const i18n = useI18n();
    const commonStore = useCommonStore()
    const agentStore = useAgentStore()
    const ib4Store = useIB4Store()

    return {
      agentStore,
      commonStore,
      i18n,
      ib4Store
    }
  },
  // data() {
  //   return {
  //     uuid: '',
  //   }
  // },
  methods: {
    checkCRMURL() {
      const sessionId = getCookie('sessionId');
      const refreshToken = getCookie('refreshToken');

      // Define a map for URL configurations
      const urlConfig = {
        localhost: {
          loginUrl: 'https://stg-crm-nginx.finpoints.tech/login',
          homeUrl: 'https://stg-crm-nginx.finpoints.tech/home',
          resetPasswordUrl: 'https://stg-crm-nginx.finpoints.tech/recovery',
        },
        dev: {
          loginUrl: 'https://dev-crm-nginx.finpoints.tech/login',
          homeUrl: 'https://dev-crm-nginx.finpoints.tech/home',
          resetPasswordUrl: 'https://dev-crm-nginx.finpoints.tech/recovery',
        },
        stg: {
          loginUrl: 'https://stg-crm-nginx.finpoints.tech/login',
          homeUrl: 'https://stg-crm-nginx.finpoints.tech/home',
          resetPasswordUrl: 'https://stg-crm-nginx.finpoints.tech/recovery',
        },
        uat: {
          loginUrl: 'https://demotestuser.doo-666.com/login',
          homeUrl: 'https://demotestuser.doo-666.com/home',
          resetPasswordUrl: 'https://demotestuser.doo-666.com/recovery',
        },
        pre: {
          loginUrl: 'https://demotestuser.doo-666.com/login',
          homeUrl: 'https://demotestuser.doo-666.com/home',
          resetPasswordUrl: 'https://demotestuser.doo-666.com/recovery',
        },
        default: {
          loginUrl: 'https://www.dooprimep1.com/login',
          homeUrl: 'https://www.dooprimep1.com/home',
          resetPasswordUrl: 'https://www.dooprimep1.com/recovery',
        },
      };

      // Determine the environment based on the host
      const host = window.location.host;
      let environment = 'default';

      if (host.includes('localhost')) {
        environment = 'localhost';
      } else if (host.includes('dev')) {
        environment = 'dev';
      } else if (host.includes('stg')) {
        environment = 'stg';
      } else if (host.includes('uat')) {
        environment = 'uat';
      } else if (host.includes('pre')) {
        environment = 'pre';
      }

      // Get the corresponding URLs
      const { loginUrl, homeUrl, resetPasswordUrl } = urlConfig[environment];

      // Set URLs in the store
      this.commonStore.setCrmLoginUrl(loginUrl);
      this.commonStore.setCrmHomeUrl(homeUrl);
      this.commonStore.setCrmResetPasswordUrl(resetPasswordUrl);

      // Append sessionId and refreshToken if available
      if (sessionId && refreshToken) {
        this.commonStore.setCrmHomeUrl(`${homeUrl}?sessionId=${sessionId}&refreshToken=${refreshToken}`);
      }
    },
    // checkCRMURL() {
    //   const sessionId = getCookie('sessionId');
    //   const refreshToken = getCookie('refreshToken');
    //   if (window.location.host.includes('localhost')) {
    //     this.commonStore.setCrmLoginUrl('https://stg-crm-nginx.finpoints.tech/login')
    //     this.commonStore.setCrmHomeUrl('https://stg-crm-nginx.finpoints.tech/home')
    //     this.commonStore.setCrmResetPasswordUrl('https://stg-crm-nginx.finpoints.tech/recovery')
    //     if (sessionId && refreshToken) {
    //       this.commonStore.setCrmHomeUrl(`https://stg-crm-nginx.finpoints.tech/home?sessionId=${sessionId}&refreshToken=${refreshToken}`)
    //     }
    //     // this.commonStore.setCrmLoginUrl('https://dev-crm-nginx.finpoints.tech/login')
    //     // this.commonStore.setCrmHomeUrl('https://dev-crm-nginx.finpoints.tech/home')
    //     // this.commonStore.setCrmResetPasswordUrl('https://dev-crm-nginx.finpoints.tech/recovery')
    //   } else if (window.location.host.includes('dev')) {
    //     this.commonStore.setCrmLoginUrl('https://dev-crm-nginx.finpoints.tech/login')
    //     this.commonStore.setCrmHomeUrl('https://dev-crm-nginx.finpoints.tech/home')
    //     this.commonStore.setCrmResetPasswordUrl('https://dev-crm-nginx.finpoints.tech/recovery')
    //     if (sessionId && refreshToken) {
    //       this.commonStore.setCrmHomeUrl(`https://dev-crm-nginx.finpoints.tech/home?sessionId=${sessionId}&refreshToken=${refreshToken}`)
    //     }
    //   }
    //   else if (window.location.host.includes('stg')) {
    //     this.commonStore.setCrmLoginUrl('https://stg-crm-nginx.finpoints.tech/login')
    //     this.commonStore.setCrmHomeUrl('https://stg-crm-nginx.finpoints.tech/home')
    //     this.commonStore.setCrmResetPasswordUrl('https://stg-crm-nginx.finpoints.tech/recovery')
    //     if (sessionId && refreshToken) {
    //       this.commonStore.setCrmHomeUrl(`https://stg-crm-nginx.finpoints.tech/home?sessionId=${sessionId}&refreshToken=${refreshToken}`)
    //     }
    //   } else if (window.location.host.includes('uat')) {
    //     // this.commonStore.setCrmLoginUrl('https://uat-crm-client-grayscale-b.finpoints.tech/login')
    //     // this.commonStore.setCrmHomeUrl('https://uat-crm-client-grayscale-b.finpoints.tech/home')
    //     // this.commonStore.setCrmResetPasswordUrl('https://uat-crm-client-grayscale-b.finpoints.tech/recovery')
    //     this.commonStore.setCrmLoginUrl('https://demotestuser.doo-666.com/login')
    //     this.commonStore.setCrmHomeUrl('https://demotestuser.doo-666.com/home')
    //     this.commonStore.setCrmResetPasswordUrl('https://demotestuser.doo-666.com/recovery')
    //     if (sessionId && refreshToken) {
    //       this.commonStore.setCrmHomeUrl(`https://demotestuser.doo-666.com/home?sessionId=${sessionId}&refreshToken=${refreshToken}`)
    //     }
    //   } else {
    //     this.commonStore.setCrmLoginUrl('https://www.dooprimep1.com/login')
    //     this.commonStore.setCrmHomeUrl('https://www.dooprimep1.com/home')
    //     this.commonStore.setCrmResetPasswordUrl('https://www.dooprimep1.com/recovery')
    //   }
    // },

    async track() {

      const collectionId = getCollectionId();

      const trackID = getCookie('track_id');

      if (collectionId !== null && trackID === null) {

        const utmPath: any = getUtmPath();

        const data = {
          "equipmentNumber": window.navigator.userAgent,
          "browseTime": this.formatDate(Date.now()),
          "domain": window.location.href,
          "collectionId": collectionId,
          "utmSource": utmPath['utm_source'] || '',
          "utmMedium": utmPath['utm_medium'] || '',
          "utmCampaign": utmPath['utm_campaign'] || '',
          "utmTerm": utmPath['utm_term'] || '',
          "utmContent": utmPath['utm_content'] || '',
          "parentUuid": "",
          "source": window.location.hostname,
          "linkCode": ""
        }

        const track = await trackId(data);
      }
    },
    async redirectEvent() {
      let token: string = window.location.href.split("token=")[1];
      //console.log('token', token)
      if (token) {

        this.$router.push(`/sc?token=${token}`)
      }
    },

    formatDate(date: Date) {
      const yyyy = new Date(date).getFullYear();
      const mm = ('0' + (new Date(date).getMonth() + 1)).slice(-2)
      const dd = ('0' + (new Date(date).getDate())).slice(-2);
      const hh = ('0' + (new Date(date).getHours())).slice(-2);
      const MM = ('0' + (new Date(date).getMinutes())).slice(-2);
      const ss = ('0' + (new Date(date).getSeconds())).slice(-2)

      return yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + MM + ':' + ss;
    },
    async sentry() {
      try {
        const { data } = await getLocation();
        Sentry.setTag("country", data.country);
        Sentry.setTag("region", data.region);
        Sentry.setTag("city", data.city);
      } catch (error) {
        console.log(error)
      }

    }

  },
  created() {
    this.track();
    this.checkCRMURL();
    this.redirectEvent();
    // this.sentry();
  },
}
</script>

<style lang="scss">
body {
  margin: inherit;
  padding: 0;
  box-sizing: border-box;
}
</style>