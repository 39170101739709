import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import TravelView from "@/views/TravelView.vue";
import Kol from "../views/Kol.vue";
import updateRank from "../views/updateRank.vue";
import Root from "./Root.vue";
import KrView from "../views/KrView.vue";
import ScView from "../views/ScView.vue";
import JpView from "../views/JpView.vue";
import TcView from "../views/TcView.vue";
import RankDetail from "../views/ib6/RankDetail.vue";
import LevelDetail from "@/views/ib6/LevelDetail.vue";
import AnnualDetail from "@/views/ib6/AnnualDetail.vue";
import RewardClaimHistory from "@/views/ib6/RewardClaimHistory.vue";
import BenefitDetail from "@/views/ib6/BenefitDetail.vue";
import ClaimedPrizeRecord from "@/views/ib6/ClaimedPrizeRecord.vue";
import { getLocation } from "@/api/location";
import { getCookie } from "@/service/cookie";
import { useAgentStore } from "@/stores/user";
import Jpdootravel from "@/views/jpdootravel.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      // component: ScView,
      component: Home,
      // redirect: to => {
      //   let target = checkDetection();
      //   return target
      // }, //await checkDetection()
    },
    {
      path: "/testmts",
      name: "testMts",
      component: Home,
    },
    // {
    //   path: "/kol",
    //   component: Root,
    //   children: [
    //     {
    //       path: "/kol",
    //       name: "kol",
    //       component: Kol,
    //       meta: {
    //         title:
    //           "Partner with Doo Prime - Exclusive Rewards & Benefits for Influencers",
    //         metaTags: [
    //           {
    //             name: "description",
    //             content:
    //               "Join the Doo Prime influencer program to earn rewards and amplify your audience. Exclusive benefits for KOLs. Apply now to partner with us!",
    //           },
    //           {
    //             name: "keywords",
    //             content:
    //               "Doo Prime, KOL, influencer marketing, influencer program, rewards, benefits, bonus, finance influencers, trading app, forex influencers, cryptocurrency influencers, affiliate program, Manchester United",
    //           },
    //         ],
    //       },
    //     },
    //     {
    //       path: "/kol/updateRank",
    //       name: "updateRank",
    //       component: updateRank,
    //       meta: {
    //         title:
    //           "Partner with Doo Prime - Exclusive Rewards & Benefits for Influencers",
    //         metaTags: [
    //           {
    //             name: "description",
    //             content:
    //               "Join the Doo Prime influencer program to earn rewards and amplify your audience. Exclusive benefits for KOLs. Apply now to partner with us!",
    //           },
    //           {
    //             name: "keywords",
    //             content:
    //               "Doo Prime, KOL, influencer marketing, influencer program, rewards, benefits, bonus, finance influencers, trading app, forex influencers, cryptocurrency influencers, affiliate program, Manchester United",
    //           },
    //         ],
    //       },
    //     },
    //   ],
    // },
    {
      path: "/:lang",
      component: Root,
      children: [
        // {
        //   path: "/sc/9years",
        //   name: "Sc9yearsView",
        //   component: Home,
        //   meta: {
        //     title:
        //       'Doo Group 9周年代理限时回馈 -  最高领 "与Doo同游" 迪拜奢华之旅！',
        //     metaTags: [
        //       {
        //         name: "description",
        //         content:
        //           "Doo Group 9周年代理限时回馈重磅来袭! 为答谢一直与Doo Prime 同行的合作伙伴，现诚意献上新一波的限时奖励，最高可享6日5晚迪拜奢华之旅! 立即行动，与Doo飞跃更高更远!",
        //       },
        //       {
        //         name: "keywords",
        //         content:
        //           "Doo Prime, Doo Group, 代理, 升级奖励, 奖励, 周年, 9周年, 介绍经纪商, 奖金, 现金奖励, 互联网券商, 证券, 期货, 货币对, 贵金属, 大宗商品, 股票指数, MT4, MT5, Doo Prime Intrade, Trading view, Outrade, Myfxbook, FOLLOWME, 社区化交易, 返佣, PAMM",
        //       },
        //     ],
        //   },
        // },
        // {
        //   path: "/tc/9years",
        //   name: "Tc9yearsView",
        //   component: Home,
        //   meta: {
        //     title:
        //       'Doo Group 9周年代理限時回饋 -  最高領 "與Doo同遊" 迪拜奢華之旅！',
        //     metaTags: [
        //       {
        //         name: "description",
        //         content:
        //           "Doo Group 9周年代理限時回饋重磅來襲!為答謝一直與Doo Prime 同行的合作夥伴，現誠意獻上新一波的限時獎勵，最高可享6日5晚迪拜奢華之旅! 立即行動，與Doo飛躍更高更遠!",
        //       },
        //       {
        //         name: "keywords",
        //         content:
        //           "Doo Prime, Doo Group, 代理, 升級獎勵, 獎勵, 周年, 9周年, 介紹經紀商, 獎金, 現金獎勵, 互聯網券商, 證券, 期貨, 貨幣對, 貴金屬, 大宗商品, 股票指數, MT4, MT5, Doo Prime Intrade, Trading view, Outrade, Myfxbook, FOLLOWME, 社區化交易, 返傭, PAMM",
        //       },
        //     ],
        //   },
        // },
        // {
        //   path: "/kr/9years",
        //   name: "Kr9yearsView",
        //   component: Home,
        //   meta: {
        //     title:
        //       "Doo Group 9주년 파트너 타임 리워드 - 최대 두바이 럭셔리 투어!",
        //     metaTags: [
        //       {
        //         name: "description",
        //         content:
        //           "항상 Doo Prime과 함께해 주신 파트너 여러분께 감사의 마음을 담아 새로운 타임 리워드를 선사하며 최대 5박6일의 두바이 럭셔리 투어를 즐기실 수 있습니다!",
        //       },
        //       {
        //         name: "keywords",
        //         content: "선물, 통화쌍, 귀금속, 원자재, 주가지수, MT4, MT5",
        //       },
        //     ],
        //   },
        // },
        // {
        //   path: "/jp/9years",
        //   name: "Jp9yearsView",
        //   component: Home,
        //   meta: {
        //     title:
        //       "Doo Group 9周年記念 期間限定特典は「Travel with Doo」ドバイラグジュアリーツアー",
        //     metaTags: [
        //       {
        //         name: "description",
        //         content:
        //           "DooGroup　9周年記念の期間限定特典！いつもDooPrimeをご利用に頂いている皆様へ感謝の気持ちを込めて、最大5泊６日のドバイラグジュアリーツアーを含む、新たな特典をプレゼント！ 今すぐ参加して、Dooともっと高く、もっと遠くへ!",
        //       },
        //       {
        //         name: "keywords",
        //         content:
        //           "Doo Prime, Doo Group, エージェント, アップグレードボーナス, ボーナス, 周年, 9周年,IB, ボーナス、現金ボーナス、海外FX、証券、先物、通貨ペア、貴金属、コモディティ、株価指数，MT4，MT5， Doo Prime Intrade， Trading view， Outrade， Myfxbook， FOLLOWME， コミュニティの取引. リベート. PAMM",
        //       },
        //     ],
        //   },
        // },
        // {
        //   path: "/kr",
        //   name: "KrView",
        //   component: Home,
        //   meta: {
        //     title:
        //       "Doo Prime IB 신년 혜택  골드 거래 더블 보너스! 최고 USD 168,888.",
        //     metaTags: [
        //       {
        //         name: "description",
        //         content:
        //           "Doo Prime IB 신년 혜택  골드 거래 더블 보너스!항상 Doo Prime과 동행해 주신 파트너 여러분께 감사드리며 새로운 타임 리워드를 성심성의껏 드립니다.이번에 신설된 골드 거래 플러스 보너스는 최대 168,888달러를 받을 수 있습니다!",
        //       },
        //       {
        //         name: "keywords",
        //         content:
        //           "Doo Prime, Doo Group, 파트너 업그레이드 보너스, 보너스, 브로커 소개, 달러, 달러 리워드, 현금 리워드, 플러스 리워드, 골드,온라인 증권사, 선물, 통화쌍, 귀금속, 원자재, 주가지수,MT4, MT5, Doo Prime Intrade, Trading view, Outrade, Myfxbook, FOLLOWME , 커뮤니티거래, 커미션, PAMM",
        //       },
        //     ],
        //   },
        // },
        {
          path: "/tc",
          name: "TcView",
          component: Home,
          meta: {
            title: "Doo Prime 金牌代理成就計劃",
            metaTags: [
              {
                name: "description",
                content:
                  "Doo Prime 誠意推出全新金牌代理成就計劃，期望與您共創財富之路。我們將爲每位金牌代理提供每季度超過 25 萬美元的獎金，活動期間內完成指定任務還可贏取迪拜房産一套，價值 200 萬美元！立即登錄瞭解更多活動詳情！",
              },
              {
                name: "keywords",
                content:
                  "Doo Group, Doo Prime, 德璞, 德璞資本, 獎勵,  旅游, 豪禮,  2024， 10 周年，活動，同行，突破，美元，美元獎勵，曼聯，曼徹斯特，金牌，亞洲，豪華游，迪拜，房産，獎金",
              },
            ],
          },
        },
        {
          path: "/:lang/testmts",
          name: "testMts",
          component: Home,
        },

        {
          path: "/jp/jpdootravel",
          name: "jpDooTravel",
          component: Jpdootravel,
          meta: {
            title: "Dooトラベル：取引して、素敵な旅へ！",
            metaTags: [
              {
                name: "description",
                content:
                  "Doo PrimeのIBとして活躍し、世界各国への特別な旅行を獲得しましょう！お客様のご資金や取引量が増えるにつれ、旅行の選択肢もより豊富になります。",
              },
              {
                name: "keywords",
                content:
                  "Doo Prime, IB, Dooトラベル, 報酬, スペシャルオファー, 旅行, タイ, 香港, オーストラリア, アラブ首長国連邦, UAE,外国為替, 原油, ゴールド, 貴金属, FX, 信頼できるブローカー, 現金, 賞金,トレーディング, MT4, MT5, InTrade, Copy Trading, FOLLOWME, リベート",
              },
            ],
          },
        },
        {
          path: "/sc",
          name: "ScView",
          component: Home,
          meta: {
            title: "Doo Prime 金牌代理成就计划",
            metaTags: [
              {
                name: "description",
                content:
                  "Doo Prime 诚意推出全新金牌代理成就计划，期望与您共创财富之路。我们将为每位金牌代理提供每季度超过 25 万美元的奖金，活动期间内完成指定任务还可赢取迪拜房产一套，价值 200 万美元！立即登录了解更多活动详情！",
              },
              {
                name: "keywords",
                content:
                  "Doo Group, Doo Prime, 德璞, 德璞资本, 奖励,  旅游, 豪礼,  2024， 10 周年，活动，同行，突破，美元，美元奖励，曼联，曼彻斯特，金牌，亚洲，豪华游，迪拜，房产，奖金",
              },
            ],
          },
        },
        {
          path: "/:lang/levelDetail",
          name: "LevelDetail",
          component: LevelDetail,
          meta: {
            title: "Doo Prime 金牌代理成就计划",
          },
        },
        {
          path: "/:lang/rankDetail",
          name: "RankDetail",
          component: RankDetail,
          meta: {
            title: "Doo Prime 金牌代理成就计划",
          },
        },
        {
          path: "/:lang/annualDetail",
          name: "AnnualDetail",
          component: AnnualDetail,
          meta: {
            title: "Doo Prime 金牌代理成就计划",
          },
        },
        {
          path: "/:lang/rewardClaimHistory",
          name: "RewardClaimHistory",
          component: RewardClaimHistory,
          meta: {
            title: "Doo Prime 金牌代理成就计划",
          },
        },
        {
          path: "/:lang/benefitDetail",
          name: "BenefitDetail",
          component: BenefitDetail,
          meta: {
            title: "Doo Prime 金牌代理成就计划",
          },
        },
        {
          path: "/:lang/claimedHistory",
          name: "ClaimedPrizeRecord",
          component: ClaimedPrizeRecord,
          meta: {
            title: "Doo Prime 金牌代理成就计划",
          },
        },

        // {
        //   path: "thdootravel",
        //   component: TravelView,
        //   name: "TravelView",
        // },
        // {
        //   path: "thdootravelnew",
        //   component: TravelView,
        //   name: "TravelViewNew",
        // },
      ],
    },
  ],
});

// async function checkDetection() {
//   console.log('ini')
//   const { data } = await getLocation();
//   console.log('ini2')

//   let lang = "sc";

//   if (location != "CN") {
//     lang = "tc";
//   }
//   console.log('ini4')

//   console.log(lang)
//   return `/${lang}`;
// }
const isLoggedIn = async () => {
  const token = getCookie("JSID");
  const uuid = getCookie("uuid");
  if (token != null && token != undefined && token != "") {
    const agentStore = useAgentStore();
    agentStore.setIsLogin(true);
    agentStore.setUuid(uuid);
  }
};

router.beforeEach(async (to, from, next) => {
  await isLoggedIn();
  if (to.path == "/") {
    const { data } = await getLocation();

    if (data.country_code == "CN") {
      router.push("/sc");
    } else if (data.country_code == "TW") {
      router.push("/tc");
    } else if (data.country_code == "KR") {
      router.push("/kr");
    } else {
      router.push("/sc");
    }
  }
  // else if (to.path == "/sc") {
  //   router.push("/sc/9years");
  // } else if (to.path == "/tc") {
  //   router.push("/tc/9years");
  // } else if (to.path == "/kr") {
  //   router.push("/kr/9years");
  // }
  // switch(to.path) {
  //   case "/kol":
  //     router.push("/en");
  //     break;
  //   default:
  //     router.push("/sc");
  // }

  if (to.path.includes("/kol")) {
    // i18n.global.locale.value = "en";
  }

  // document.documentElement.setAttribute("lang", i18n.global.locale.value);

  if (to?.meta?.title != undefined) {
    document.title = to.meta?.title as string;
  }

  if (to?.meta?.metaTags != undefined) {
    for (const [key, value] of Object.entries(to.meta.metaTags)) {
      const tag = document.createElement("meta");
      Object.keys(value).forEach((key) => {
        tag.setAttribute(key, value[key]);
      });
      document.head.appendChild(tag);
    }
  }

  return next();
});

export default router;
