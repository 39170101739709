import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import setupI18n from "./i18n";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import { createMetaManager } from "vue-meta";
import Countdown from "vue3-flip-countdown";
import { plugin, defaultConfig } from "@formkit/vue";
import * as Sentry from "@sentry/vue";
import Vue3Marquee from "vue3-marquee";

(async () => {
  const i18n = await setupI18n();

  const app = createApp(App);

  // if (false) {
  //   Sentry.init({
  //     dsn: `${window.location.protocol}//${
  //       import.meta.env.VITE_SENTRY_PROKEY
  //     }@${window.location.host}/sentry/${import.meta.env.VITE_SENTRY_PROID}`,
  //     app,
  //     environment: import.meta.env.MODE,
  //     //@ts-expect-error
  //     release: __APP_RELEASE__,
  //     integrations: [new Sentry.BrowserTracing()],
  //     tracesSampleRate: 1.0,
  //   });
  // }

  app.use(i18n);
  app.use(router);
  app.use(Countdown);
  app.use(VueAxios, axios);
  app.use(
    createMetaManager(false, {
      keywords: {
        tag: "meta",
      },
      description: {
        tag: "meta",
      },
    })
  );

  app.use(createPinia());
  app.use(plugin, defaultConfig);
  app.use(Vue3Marquee);

  app.mount("#app");
})();
