<template>
    <section class="top_notch_trading_enviroment">
        <h2>{{ $t("ib6.whyDoo.title") }}</h2>
        <p>{{ $t("ib6.whyDoo.description") }}</p>
        <div class="carousel-container" v-if="commonStore.agentLocation">
            <swiper style="--swiper-pagination-color: #de2026" :slidesPerView="1" :autoplay="(true as any)"
                :centeredSlides="(true as any)" :pagination="(pagination as any)" :spaceBetween="2" :loop="(true as any)"
                :modules="modules" class="mySwiper">
                <swiper-slide>
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_regulations.png">
                    <h4>{{ $t("ib6.whyDoo.swiper1.title") }}</h4>
                    <p>{{ $t("ib6.whyDoo.swiper1.content") }}</p>
                </swiper-slide>
                <swiper-slide>
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_spread.png">

                    <h4>{{ $t("ib6.whyDoo.swiper2.title") }}</h4>
                    <p>{{ $t("ib6.whyDoo.swiper2.content") }}</p>
                </swiper-slide>
                <swiper-slide>
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_cfd.png">
                    <h4>{{ $t("ib6.whyDoo.swiper3.title") }}</h4>
                    <p>{{ $t("ib6.whyDoo.swiper3.content") }}</p>
                </swiper-slide>
                <swiper-slide>
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_support.png">

                    <h4>{{ $t("ib6.whyDoo.swiper4.title") }}</h4>
                    <p>{{ $t("ib6.whyDoo.swiper4.content") }}</p>
                </swiper-slide>
                <swiper-slide>
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_speed.png">
                    <h4>{{ $t("ib6.whyDoo.swiper5.title") }}</h4>
                    <p>{{ $t("ib6.whyDoo.swiper5.content") }}</p>
                </swiper-slide>
                <swiper-slide>
                    <img v-if="Object.keys(leverage).length > 0" :src="this.leverage.image">
                    <h4 v-if="Object.keys(leverage).length > 0">{{ this.leverage.home_page_title[$i18n.locale] }}</h4>
                    <p v-if="Object.keys(leverage).length > 0">{{ this.leverage.home_page_content[$i18n.locale] }}</p>
                </swiper-slide>
            </swiper>
        </div>
        <div :id="`${commonStore.currentEvent}_login3_visitor`" class="btn" v-if="agentStore.isLogin == false">
            <img :src="imageUrl + 'CTA_button.svg'" alt="">
            <a @click="this.commonStore.fetchCRMIdTags()">{{ $t("ib6.whyDoo.login") }}</a>
        </div>
    </section>
</template>

<script lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// import required modules
import { Navigation, Pagination, Autoplay, Controller } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import "swiper/swiper.min.css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/controller';
import 'swiper/css/autoplay';
import { useCommonStore } from '@/stores/common';
import { useAgentStore } from '@/stores/user';
import { leverage } from "@/api/leverage";


export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore,
            modules: [Navigation, Pagination, Autoplay, Controller],
        };
    },
    data() {
        return {
            pagination: { clickable: true as any },
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore(),
            agentStore: useAgentStore(),
            leverage: {},
        }
    },
    methods: {
        async getLeverage() {
            const { data } = await leverage();
            const countryCode = this.commonStore.agentLocation.toUpperCase();
            if (data.hasOwnProperty(countryCode)) {
                this.leverage = data[countryCode];
            } else {
                this.leverage = data['default'];
            }
        }
    },
    mounted() {
        this.getLeverage();
    },
    watch: {
        commonStore: {
            handler(newValue, oldValue) {
                if (newValue.agentLocation != 'CN') {
                    this.getLeverage();
                }
            },
            deep: true
        }
    },
};


</script>

<style lang="scss" >
.top_notch_trading_enviroment {
    padding-top: 40px;
    // padding: 64px 32px 80px;
    // background-color: #fff;

    h2 {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        padding: 0;
        margin: 0 0 12px;
        text-transform: uppercase;
    }

    >p {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #3a424b;
        margin-bottom: 16px;
    }

    .carousel-container {
        img {
            max-width: 175px;
            margin-bottom: 8px;
        }

        h4 {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;
        }

        margin: 0 0 8px;
    }

    p {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #6d6d6d;
        margin-bottom: 32px;
    }

    .swiper-pagination {
        bottom: -3px !important
    }

    // .swiper-pagination-bullet {
    //     width: 16px;
    //     height: 2px;
    //     border-radius: 2px;
    // }

}

.btn {
    margin: 33px auto 30px auto;
    display: block;
    cursor: pointer;

    img {
        max-width: 200px;
        position: relative;
    }

    a {
        position: relative;
        font-family: MicrosoftYaHeiSemibold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #fff;
        display: block;
        top: -48px;
    }
}
</style>