<template>
  <div id="event_description">
    <div id="tab_container" :class="{ sticky_bg: stickyTab }">
      <!-- <div class="tab_arrow" ref="tabArrow">
        <span class="arrow_left" :class="{ greyscale: arrowLeft }">&#9666;</span>
        <span class="arrow_right" :class="{ greyscale: arrowRight }">&#9656;</span>
      </div> -->
      <div class="tab">
        <a :href="`#tab_${n.id}`" class="tab_btn" v-for="(n, index) in totalTab" :key="n.id" :id="n.btnId"
          :class="{ active: activeTab === index + 1 }" @click="setActiveTab(n.id)">
          {{ $t(`ib6.eventTab.tab${n.id}`) }}
        </a>
      </div>
    </div>

    <div class="container">
      <div id="tab_content" ref="content">
        <EventLevel />

        <div id="login_event_box">
          <img :src="imageUrl + 'gold_chain.png'" class="gold_coin" />
          <div class="login_event_content">
            <p class="login_event_description">
              {{ $t(`ib6.eventDescriptions.description3`) }}
            </p>
          </div>
          <a @click="this.commonStore.fetchCRMIdTags()" class="login_btn"
            :id="`${commonStore.currentEvent}_login2_visitor`">
            <button>
              <p>{{ $t(`ib6.eventInfo.login`) }}</p>
            </button>
          </a>
        </div>

        <EventRank />
        <EventAnnual />
        <!-- <EventBenefit /> -->
        <ComingSoon />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EventLevel from "@/views/ib6/home_event/EventLevel.vue";
import EventRank from "@/views/ib6/home_event/EventRank.vue";
import EventAnnual from "@/views/ib6/home_event/EventAnnual.vue";
import ComingSoon from "@/components/ComingSoon.vue";
import EventBenefit from "@/views/ib6/home_event/EventBenefit.vue";
import { useCommonStore } from "@/stores/common";

export default {
  data() {
    return {
      // totalTab: 3,
      totalTab: [
        {
          "id": 1,
          "btnId": `${useCommonStore().currentEvent}_level_visitor`
        }, {
          "id": 2,
          "btnId": `${useCommonStore().currentEvent}_ranking_visitor`
        }, {
          "id": 3,
          "btnId": `${useCommonStore().currentEvent}_yearlylevel_visitor`
        }
      ],
      arrowLeft: true,
      arrowRight: false,
      activeTab: 1,
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      commonStore: useCommonStore(),
      stickyTab: false,
    };
  },
  methods: {
    setActiveTab(n: number) {
      this.activeTab = n;
      // this.arrowLeft = n < this.totalTab ? true : false;
      // this.arrowRight = n === this.totalTab ? true : false;
    },
    tab() {
      const contentPosition = this.$refs.content.getBoundingClientRect().top;
      if (contentPosition < 0) {
        this.stickyTab = true;
      } else {
        this.stickyTab = false;
      }
    },
  },
  mounted() {
    this.tab();
    window.addEventListener("scroll", this.tab);
  },
  unmounted() {
    window.removeEventListener("scroll", this.tab);
  },
  components: {
    EventLevel,
    EventRank,
    EventAnnual,
    EventBenefit,
    ComingSoon,
  },
};
</script>

<style lang="scss">
#event_description {
  margin-bottom: 40px;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }

  .arrow_right,
  .arrow_left {
    color: #d91d22;
    font-size: 18px;
  }

  .sticky_bg {
    position: sticky !important;
    top: 0;
    box-shadow: 0 2px 12px 0 rgba(151, 151, 151, 0.3);
    background-image: linear-gradient(to bottom,
        rgba(253, 209, 210, 0.96),
        #fff);
  }

  #tab_container {
    display: flex;
    align-items: center;
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding: 16px 16px 20px 16px;
    z-index: 999;
    position: relative;

    p {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin: 0;
    }

    .tab_arrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      z-index: -1;
      width: 100%;
      left: 0;

      .greyscale {
        color: #d8d8d8;
      }
    }

    .tab {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      height: 32px;
      width: 100%;
      border-radius: 6px;
      border-style: solid;
      border-width: 2px;
      border-image-source: linear-gradient(96deg,
          #e0e0e0 6%,
          #fff 26%,
          #e0e0e0 53%,
          #fff 72%,
          #e0e0e0 87%);
      border-image-slice: 0;
      background-image: linear-gradient(to bottom, #fff, #fff),
        linear-gradient(96deg,
          #e0e0e0 6%,
          #fff 26%,
          #e0e0e0 53%,
          #fff 72%,
          #e0e0e0 87%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      z-index: 999;

      ::-webkit-scrollbar {
        display: none;
      }

      a {
        width: 100%;
        height: 32px;
        padding: 6px 15px;
        background: transparent;
        border-color: transparent;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }

      .active {
        background-color: #d91d22;
        color: #fff;
      }
    }
  }

  #login_event_box {
    width: 100%;
    height: 66px;
    padding: 0 16px 0 1px;
    display: flex;
    align-items: center;
    border-radius: 47px;
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1);
    border: 1px solid #ffebc6;
    background-image: linear-gradient(to bottom, #fff5e3, rgba(255, 245, 227, 0)),
      linear-gradient(to bottom, #fff5e3, rgba(255, 245, 227, 0));

    .login_btn {
      margin: auto 0 auto auto;
    }

    .gold_coin {
      width: 52.7px;
      height: 65px;
    }

    .login_event_content {
      .login_event_description {
        max-width: 286px;
        margin: 0 24px 0 3.3px;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #3a424b;
        margin: 0;
      }
    }

    button {
      width: 96px;
      height: 36px;
      padding: 0;
      border-radius: 21px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      border-style: solid;
      border-width: 2px;
      border-image-source: linear-gradient(94deg,
          #f1353a 1%,
          #ffc1c2 24%,
          rgba(241, 180, 154, 0.57) 50%,
          #ffc1c2 72%,
          #f1353a 97%);
      border-image-slice: 0;
      background-image: linear-gradient(to bottom, #d91d22, #d91d22),
        linear-gradient(94deg,
          #f1353a 1%,
          #ffc1c2 24%,
          rgba(241, 180, 154, 0.57) 50%,
          #ffc1c2 72%,
          #f1353a 97%);
      background-origin: border-box;
      background-clip: content-box, border-box;

      p {
        margin: 0;
        padding: 7px 16px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        // letter-spacing: 1px;
        text-align: center;
        color: #fff;
      }
    }
  }
}

html[lang="kr"] #event_description {
  .title {
    font-family: NotoSansBold;
  }
}
</style>
