<template>
    <div class="modal-content PassEventRecord">
        <p class="title">{{ $t(`ib6.modal.${commonStore.previousEventName}`) }}</p>
        <div class="level_wrapper">
            <p v-if="previousResultApiData.agentLevel == 0">{{ $t('ib6.modal.failArchiveTitle') }}</p>
            <p v-if="previousResultApiData.agentLevel > 0">{{ $t('ib6.modal.archiveLevelTitle') }}</p>
            <img v-if="previousResultApiData.agentLevel > 0" class="bg_level"
                :src="imageUrl + `LV${previousResultApiData.agentLevel}.png`" alt="">
        </div>
        <div class="prize_wrapper">
            <img :src="imageUrl + 'level_reward.png'" alt="">
            <p>{{ $t('ib6.modal.prizeArchiveTitle') }}</p>
        </div>
        <div class="wrapper">
            <div>
                <p>{{ $t('ib6.monthlyStatistics.validCustomer') }}</p>
                <p>
                    <span class="active">{{ previousResultApiData.qualifiedCustomersNumber }}</span>
                </p>
            </div>
            <div>
                <p>{{ $t('ib6.monthlyStatistics.topUp') }}</p>
                <p>
                    <span class="active">{{ previousResultApiData.eventInGold }}</span>
                </p>
            </div>
            <div>
                <p>{{ $t('ib6.monthlyStatistics.trade') }}</p>
                <p>
                    <span class="active">{{ previousResultApiData.tradingVolume }}</span>
                </p>
            </div>

        </div>
        <p class="total_achieve_title">{{ $t('ib6.modal.totalAchieveTitle')
        }}{{ previousResultApiData.totalCommission }}</p>
        <div class="divider"></div>
        <div class="prize_wrapper">
            <img :src="imageUrl + 'gold_reward.png'" alt="">
            <p>{{ $t('ib6.modal.goldArchiveTitle') }}</p>
        </div>
        <div class="wrapper">
            <div>
                <p>{{ $t('ib6.monthlyStatistics.gold') }}</p>
                <p>
                    <span class="active">{{ previousResultApiData.goldAmount }}</span>
                </p>
            </div>
        </div>
        <div class="modal-btn-close" @click="commonStore.closeModal()">
            <img :src="imageUrl2 + 'icon-close.svg'" />
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
import { ResultData } from '@/modal/Result'

export default {
    props: {
        previousResultApiData: {
            default: {} as ResultData,
        }
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            imageUrl2: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/",
            commonStore: useCommonStore(),
            isLoading: false,

        }
    },
    methods: {

    },

}
</script>

<style lang="scss" scoped >
// .modal-content-wrapper {
//     padding: 24px 16px !important;
// }


.PassEventRecord {
    padding: 23px 41px !important;
    width: 100% !important;
    max-width: 355px !important;
    background-image: url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/pass_result_bg.png') !important;
    background-size: 100% auto;
    background-repeat: no-repeat;
    box-shadow: inherit !important;

    p {
        margin: 0;
    }

    .title {
        margin-left: 7px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }

    .level_wrapper {
        margin: 23px 0 19px;
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #252525;
            text-wrap: nowrap;
        }

        .bg_level {
            width: 52px;
        }
    }

    .prize_wrapper {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        p {
            margin-left: 16px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #252525;
        }
    }

    .total_achieve_title {
        margin-top: 4px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }

    .wrapper {
        div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;

            p {
                display: flex;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: #878793;

                span {
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;


                }
            }
        }
    }

    .divider {
        margin: 15px 0 16px;
        border-top: 1px solid #c39654;
    }

    .modal-btn-close {
        margin-top: 40px;
    }
}
</style>